import styled from 'styled-components';

export const Wrapper = styled.div`
    padding-top: 80px;

    @media all and (max-width: 768px) {
        padding-top: 30px;
    }
`;
export const ContentWrapper = styled.div`
    @media all and (max-width: 992px) {
        margin-bottom: 40px;
    }

    @media all and (max-width: 601px) {
        margin-bottom: 20px;
    }

    h1 {
        margin-bottom: 24px;
    }
`;

export const IllustrationWrapper = styled.div`
    padding-left: 30px;

    svg {
        max-width: 100%;
        height: auto;
    }
`;

export const ButtonsWrapper = styled.div`
    margin-top: 24px;

    @media all and (max-width: 768px) {
        margin-top: 30px;

        a {
            font-size: 11px !important;
        }
    }

    a {
        margin: 0 20px 10px 0;
    }
`;
