import React from 'react';

// components
import Layout from '../components/Layout';
import NotFound from '../components/Pages/404';

const NotFoundPage = ({ ...rest }) => {
    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            hasContainer
            showOurCustomers={false}
            showWantToKnowMore={false}
        >
            <NotFound lng={rest.pageContext.langKey} />
        </Layout>
    );
};

export default NotFoundPage;
